<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}${
      summary && summary.total ? `: ${summary.total[0].formatted}` : ''
    }`"
    :extra-subtitle="extra"
    :loading="orders_loading"
  >
    <div v-if="$can('read', 'orders')" slot="actions">
      <div class="field has-addons">
        <div class="is-flex mr-2">
          <b-button
            v-if="$device.mobile"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon icon="magnify" size="is-small" />
          </b-button>
          <b-input
            ref="search"
            v-model="headers[2].input"
            :placeholder="placeholders.number['6']"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            icon="magnify"
            rounded
            @input="getOrders()"
            @blur="hideMobile()"
          />
        </div>
        <b-dropdown
          ref="dropdown"
          aria-role="list"
          position="is-bottom-left"
          :close-on-click="false"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              :size="$device.mobile ? 'is-small' : ''"
              type="is-default"
              rounded
            />
          </div>
          <b-dropdown-item
            v-if="$can('read', 'orders.export')"
            aria-role="listitem"
            @click="ordersInvoice"
          >
            <b-icon icon="download" size="is-small" />
            {{ $t("export_excel") }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'orders')"
            aria-role="listitem"
            @click="showMpModal = true"
          >
            <b-icon icon="swap-horizontal" size="is-small" />
            {{ $tc("marketplace", 2) }}
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item
            aria-role="listitem"
            class="pr-3"
            @click="showColumnsTogle = !showColumnsTogle"
          >
            <b-icon icon="view-column" size="is-small" />
            {{ $tc("column", 2) }}
            <b-icon
              class="is-pulled-right"
              :icon="!showColumnsTogle ? 'chevron-down' : 'chevron-up'"
              size="is-small"
            />
          </b-dropdown-item>
          <template v-if="showColumnsTogle">
            <template v-for="item in headers">
              <b-dropdown-item
                v-if="!item.group"
                :key="item.key"
                aria-role="listitem"
                :disabled="item.disabled"
                :focusable="false"
                custom
              >
                <b-switch
                  v-model="item.display"
                  :disabled="item.disabled"
                  type="is-success"
                >
                  {{ item.label }}
                </b-switch>
              </b-dropdown-item>
            </template>
          </template>
        </b-dropdown>
      </div>
    </div>

    <Card v-if="$can('read', 'orders')" content-class="c-table">
      <div class="header-container">
        <div />
        <DatesPicker :disabled="hasOrderId" @change="changeDates" />
      </div>
      <hr class="my-0" />
      <b-table
        :data="items"
        :loading="orders_loading"
        hoverable
        mobile-cards
        striped
        backend-sorting
        sort-multiple
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :total="total"
        :row-class="
          (row, index) => (row.id == updated_order ? 'bg-updated' : '')
        "
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value + '_' + head.display"
            :visible="head.display"
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="head.searchable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template v-if="head.searchable" slot="searchable">
              <template v-if="head.filter_type == 'date'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :min-date="new Date('01/01/2013')"
                  :max-date="new Date()"
                  @input="getOrders()"
                />
                <span class="custom-date-filter" @click="showDrop(head.value)">
                  <b-icon icon="filter" size="is-small" />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'is')"
                  >
                    {{ $t("is") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'greater')"
                  >
                    {{ $t("is") }} &gt;
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'less')"
                  >
                    {{ $t("is") }} &lt;
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else-if="head.filter_type == 'select'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  size="is-small"
                  :expanded="true"
                  @change="getOrders()"
                >
                  <template v-if="head.value === 'warehouse_id'">
                    <option
                      v-for="opt in warehouses"
                      :key="opt[head.filter_id]"
                      :value="opt[head.filter_id]"
                    >
                      {{ opt.country.country + " - " + opt.currency.code }}
                    </option>
                  </template>
                  <template v-else>
                    <option
                      v-for="opt in head.filter_values"
                      :key="opt[head.filter_id]"
                      :value="opt[head.filter_id]"
                    >
                      {{ opt[head.filter_text] }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.filter_type == 'autocomplete'">
                <b-autocomplete-validation
                  v-model="head.input"
                  expanded
                  fit-content
                  class="has-text-weight-normal"
                  width="100%"
                  rounded
                  size="is-small"
                  :data="head.filter_values"
                  :placeholder="placeholders.user.phone.country"
                  :item-text="head.filter_text"
                  :field="head.filter_id"
                  @change="countryChanged"
                />
              </template>
              <template v-else-if="head.filter_type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  size="is-small"
                  @change="getOrders()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else>
                <b-input
                  v-model="head.input"
                  :placeholder="$root.getFilterPlaceholder(head)"
                  rounded
                  size="is-small"
                  icon-right="filter"
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getOrders"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <!-- site -->
            <template v-if="head.value == 'site_id'" v-slot="props">
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + props.row.site.id + '.svg'"
              />
            </template>

            <!-- Num -->
            <template v-else-if="head.value === 'id'" v-slot="props">
              <a
                href="javascript:;"
                @click="
                  openDrawer('orderForm', {
                    key: props.row.id,
                    index: props.index,
                    order_id: props.row.id,
                    user_id: props.row.user.id,
                    site_id: props.row.site.id,
                    warehouse_id: warehouse_id,
                    mp_id: props.row.mp_id,
                    warehouses: warehouses,
                  })
                "
                >{{ props.row.id }}</a
              >
            </template>

            <!-- Warehouse -->
            <template v-else-if="head.value === 'warehouse_id'" v-slot="props">
              <img
                height="18"
                width="18"
                :src="'/img/flags/' + props.row.warehouse.country.iso + '.svg'"
              />
            </template>

            <!-- User -->
            <template v-else-if="head.value === 'user'" v-slot="props">
              <span>
                <router-link :to="`/users/${props.row.user.id}`">{{
                  props.row.user &&
                  props.row.user.fname + " " + props.row.user.lname
                }}</router-link>
              </span>
            </template>

            <!-- Email -->
            <template v-else-if="head.value === 'email'" v-slot="props">
              {{ props.row.user && props.row.user.email }}
            </template>

            <!-- total -->
            <template v-else-if="head.value === 'total'" v-slot="props">
              {{ props.row.total.formatted }}
            </template>

            <!-- ordered_at -->
            <template v-else-if="head.value === 'ordered_at'" v-slot="props">
              <span>
                {{
                  (props.row.ordered_at && props.row.ordered_at.formatted) ||
                  "-"
                }}
                <span class="has-text-grey-light">{{
                  props.row.ordered_at && props.row.ordered_at.time
                }}</span>
              </span>
            </template>

            <!-- status -->
            <template v-else-if="head.value === 'status'" v-slot="props">
              <b-tag :type="getStatus(props.row.status.id)">
                {{ (props.row.status && props.row.status.label) || "-" }}
              </b-tag>
            </template>

            <!-- payment -->
            <template v-else-if="head.value === 'payment'" v-slot="props">
              <span>
                {{ (props.row.payments && props.row.payments[0].label) || "-" }}
                <b-tag
                  v-if="props.row.payments && props.row.payments.length > 1"
                  size="is-small"
                  type="is-primary is-light"
                  rounded
                  class="ml-1"
                >
                  +{{ props.row.payments.length - 1 }}
                </b-tag>
              </span>
            </template>

            <!-- tracking_number -->
            <template
              v-else-if="head.value === 'tracking_numbers'"
              v-slot="props"
            >
              <span>
                {{
                  (props.row.tracking &&
                    props.row.tracking[0] &&
                    props.row.tracking[0].tracking_number) ||
                  "-"
                }}
                <b-tag
                  v-if="props.row.tracking && props.row.tracking.length > 1"
                  rounded
                  size="is-small"
                  type="is-primary is-light"
                  class="ml-1"
                >
                  +{{ props.row.tracking.length - 1 }}
                </b-tag>
              </span>
            </template>

            <!-- country -->
            <template v-else-if="head.value === 'country_iso'" v-slot="props">
              {{ (props.row.country && props.row.country.label) || "-" }}
            </template>

            <!-- salesman -->
            <template v-else-if="head.value == 'salesman'" v-slot="props">
              {{
                props.row.salesman
                  ? props.row.salesman.fname + " " + props.row.salesman.lname
                  : "-"
              }}
            </template>

            <!-- invoice -->
            <template v-else-if="head.value === 'invoice'" v-slot="props">
              <span
                v-if="props.row.invoice"
                class="is-clickable"
                @click="downloadInvoice(props.row.id)"
              >
                <b-icon icon="download" custom-size="mdi-18px" />
              </span>
              <span v-else>-</span>
            </template>

            <template v-else v-slot="props">
              <b-icon
                v-if="typeof props.row[head.value] === 'boolean'"
                custom-size="mdi-18px"
                :icon="props.row[head.value] ? 'check' : 'close'"
                :type="props.row[head.value] ? 'is-success' : 'is-danger'"
              />
              <template v-else>
                {{ props.row[head.value] || "-" }}
              </template>
            </template>
          </b-table-column>
        </template>
        <EmptyBlock slot="empty" icon="cart" />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
    <b-modal v-model="showMpModal" :width="520">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $tc("marketplace", 2) }}</b>
          </div>
          <hr class="my-0" />
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(importMpOrders)"
            class="columns is-multiline is-variable is-2 py-2"
          >
            <b-select-validation
              v-model="mpSelected"
              class="column is-6 mb-2"
              hide-details
              vid="mp"
              :label="$tc('marketplace', 1) + ' (' + $t('optional') + ')'"
            >
              <template>
                <option
                  v-for="opt in marketplaces"
                  :key="opt.id"
                  :value="opt.id"
                >
                  {{ opt.label }}
                </option>
              </template>
            </b-select-validation>
            <b-select-validation
              v-model="mpLimit"
              class="column is-3"
              vid="mplimit"
              hide-details
              rules="required"
              :label="$t('limit')"
            >
              <template>
                <option v-for="opt in mpLimits" :key="opt" :value="opt">
                  {{ opt }}
                </option>
              </template>
            </b-select-validation>
            <b-select-validation
              v-model="mpPeriod"
              class="column is-3"
              vid="mpPeriod"
              hide-details
              rules="required"
              :label="$t('period')"
            >
              <template>
                <option v-for="opt in mpPeriods" :key="opt.value" :value="opt.value">
                  {{ opt.label }}
                </option>
              </template>
            </b-select-validation>
            <b-input-validation
              v-model="mpOrder"
              class="column is-12"
              :disabled="!mpSelected"
              hide-details
              vid="mporder"
              :label="$tc('order', 1) + ' (' + $t('optional') + ')'"
              :placeholder="placeholders.order.mp_id"
            />
            
            <div class="column is-12 mt-2">
              <b-button
                class="mr-4"
                type="is-success"
                :loading="importMpOrdersLoading"
                :disabled="importMpOrdersLoading"
                tag="input"
                native-type="submit"
                :value="$t('validate')"
              >
                {{ $t("validate") }}
              </b-button>
              <b-button
                :disabled="importMpOrdersLoading"
                @click="showMpModal = false"
              >
                {{ $t("cancel") }}
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </Card>
    </b-modal>
  </Page>
</template>

<script>
import moment from "moment";
import DatesPicker from "@/components/forms/elements/DatesPicker";
import debounce from "debounce";

export default {
  components: {
    DatesPicker,
  },
  data() {
    return {
      hasOrderId: false,
      searchFocused: false,
      updated_order: null,
      showColumnsTogle: false,
      showMpModal: false,
      sortingPriority: [{ field: "ordered_at", order: "desc" }],
      singleSorting: {},
      currentPage: 1,
      perPage: 50,
      perPages: [50, 100, 200],
      orders_loading: true,
      total: 0,
      warehouse_id: null,
      warehouses: [],
      marketplaces: [],
      mpLimit: 25,
      mpSelected: null,
      mpOrder: null,
      mpLimits: [25, 40, 60, 80, 100],
      mpPeriod: 1,
      mpPeriods: [
        { label: "1 hour", value: 1 },
        { label: "6 hours", value: 6 },
        { label: "1 day", value: 24 },
        { label: "2 days", value: 48 },
        { label: "5 days", value: 120 },
        { label: "10 days", value: 240 },
      ],
      headers: [
        {
          label: this.$t("site"),
          value: "site_id",
          sortable: true,
          searchable: true,
          width: "80",
          filter_type: "select",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$tc("warehouse", 1),
          value: "warehouse_id",
          width: "80",
          sortable: true,
          searchable: true,
          filter_type: "select",
          filter_text: "country",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("num"),
          value: "id",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("num") + " " + this.$tc("marketplace", 1).toLowerCase(),
          value: "mp_id",
          sortable: true,
          searchable: true,
          width: "100",
          input: "",
          filter: "contains",
          display: false,
        },
        {
          label: this.$tc("customer", 1),
          value: "user",
          width: "180",
          sortable: true,
          searchable: true,
          input: "",
          filter: "contains",
          display: true,
        },
        {
          label: this.$t("email"),
          value: "email",
          sortable: true,
          searchable: true,
          width: "180",
          input: "",
          filter: "contains",
          display: false,
        },
        {
          label: this.$i18n.t("total"),
          value: "total",
          sortable: true,
          searchable: true,
          width: "140",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$i18n.t("date"),
          value: "ordered_at",
          sortable: true,
          searchable: true,
          width: "160",
          filter_type: "date",
          input: null,
          filter: "is",
          display: true,
        },
        {
          label: this.$tc("voucher", 1),
          value: "voucher",
          sortable: true,
          searchable: true,
          width: "100",
          input: "",
          filter: "contains",
          display: false,
        },
        {
          label: this.$t("payment_mean"),
          value: "payment",
          sortable: true,
          searchable: true,
          width: "180",
          filter_type: "select",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("status"),
          value: "status",
          sortable: true,
          searchable: true,
          width: "180",
          filter_type: "select",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$tc("country", 1),
          value: "country_iso",
          sortable: true,
          searchable: true,
          filter_type: "autocomplete",
          filter_text: "country",
          filter_id: "iso",
          width: "140",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$tc("salesman", 1),
          value: "salesman",
          sortable: true,
          searchable: true,
          width: "180",
          filter_type: "select",
          filter_text: "signature",
          filter_id: "id",
          filter_values: [],
          input: "",
          display: false,
        },
        {
          label: this.$t("tracking_number"),
          value: "tracking_numbers",
          sortable: true,
          searchable: true,
          width: "150",
          input: "",
          filter: "contains",
          display: false,
        },
        {
          label: this.$t("invoice_num"),
          value: "invoice_num",
          sortable: true,
          searchable: true,
          width: "150",
          input: "",
          filter: "is",
          display: false,
        },
        {
          label: this.$t("invoice"),
          value: "invoice",
          filter_type: "boolean",
          sortable: true,
          centered: true,
          searchable: true,
          width: "80",
          input: "",
          filter: "is",
          display: true,
        },
      ],
      items: [],
      summary: {},
      extra: [],
      dates: [],
      date_filter: {},
      countriesLoaded: false,
      init: false,
      importMpOrdersLoading: false,
    };
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
    perPage() {
      this.getOrders();
    },
  },
  created() {
    this.updateTitle(this.$i18n.tc("order", 2));
  },
  mounted() {
    this.$bus.$on("update-list", (params) => {
      let item = this.items.filter((i) => i.id === params.id);
      let index = this.items.indexOf(item[0]);

      item[0]["details"] = params.details;
      if (params.details && params.details.statuses) {
        let status = params.details.statuses.find((s) => s.selected);

        // update status
        if (status)
          item[0]["status"] = {
            id: status.id,
            label: status.status,
          };
      }
      // update total
      item[0]["total"] = params.summary.total;
      item[0]["items"] = params.items;
      this.$set(this.items, index, item[0]);
    });
  },
  destroyed() {
    this.$bus.$off("update-order-options");
  },
  methods: {
    countryChanged() {
      this.getOrders();
    },
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    closeDrop() {
      this.$refs["dropdown"].toggle();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    changeDates(dates) {
      this.date_filter.from = dates.date.from;
      this.date_filter.to = dates.date.to;
      if (!this.init) {
        this.getOrders();
      } else {
        this.init = false;
      }
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getOrders();
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.getOrders();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getOrders();
    },
    getStatus(id) {
      if (id === 2) return "is-danger";
      else if (id === 3) return "is-warning";
      else if (id === 4) return "is-success";
      else if (id === 15) return "is-purple";
    },
    importMpOrders() {
      this.importMpOrdersLoading = true;
      this.$axios
        .post("ecommerce/orders/mpimport", {
          marketplace: this.mpSelected,
          order: this.mpOrder,
          limit: this.mpLimit,
          period: this.mpPeriod,
        })
        .then(() => {
          this.showMpModal = false;
          this.mpSelected = null;
          this.mpOrder = null;
          this.mpLimit = 25;
          this.mpPeriod = 1;
          this.notify(this.$t("request_successfully_sent"), "is-success");
          this.getOrders();
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.importMpOrdersLoading = false));
    },
    ordersInvoice() {
      this.orders_loading = true;
      let api_params;
      api_params = `?warehouse_id=${this.warehouse_id || ""}`;

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      this.hasOrderId = false;
      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        const value = this.headers[i];
        if (value.input || typeof value.input == "boolean") {
          if (value.value == "id") this.hasOrderId = true;
          let obj = {
            id: value.value,
            selected:
              value.value == "ordered_at"
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }

      if (this.date_filter.from && this.date_filter.to && !this.hasOrderId) {
        api_params +=
          "&date_from=" +
          this.date_filter.from +
          "&date_to=" +
          this.date_filter.to;
      }

      if (searchArr.length)
        api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.$axios
        .get("ecommerce/orders/export" + api_params)
        .then((res) => {
          const url = URL.createObjectURL(
            new Blob([res.data.file], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.data.filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.orders_loading = false));
    },
    getOrders: debounce(function (params = null) {
      let api_params;
      let perPage = this.perPage;
      api_params = `?page=${this.currentPage}&per_page=${perPage}`;

      if (params && params.dates) {
        this.date_filter.date_from = params.dates[0];
        this.date_filter.date_to = params.dates[1];
        if (!moment(params.dates[1]).isAfter(params.dates[0])) {
          this.date_filter.date_from = params.dates[1];
          this.date_filter.date_to = params.dates[0];
        }
      }

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      this.hasOrderId = false;
      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        const value = this.headers[i];
        if (
          ["mp_id", "user", "email", "voucher"].includes(value.value) &&
          value.input &&
          value.input.length != 0 &&
          value.input.length < 3
        )
          return;
        else if (value.input || typeof value.input == "boolean") {
          if (value.value == "id") this.hasOrderId = true;
          let obj = {
            id: value.value,
            selected:
              value.value == "ordered_at"
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }

      if (this.date_filter.from && this.date_filter.to && !this.hasOrderId) {
        api_params +=
          "&date_from=" +
          this.date_filter.from +
          "&date_to=" +
          this.date_filter.to;
      }

      if (searchArr.length)
        api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.orders_loading = true;

      this.$axios
        .get("ecommerce/orders" + api_params)
        .then((res) => {
          this.items = res.data.list.length > 0 ? res.data.list : [];
          this.summary = res.data.summary;
          this.extra =
            this.summary && this.summary.total && this.summary.total.length > 1
              ? this.summary.total.slice(1)
              : [];

          this.total = res.data.pagination.total;
          this.warehouses = res.data.filters.warehouses;
          this.marketplaces = res.data.filters.marketplaces;
          if (!this.warehouse_id)
            this.warehouse_id = this.warehouses[0] && this.warehouses[0].id;

          let site_index = this.headers.findIndex((h) => h.value == "site_id");
          if (this.headers[site_index].filter_values.length == 0)
            this.headers[site_index].filter_values = res.data.filters.sites;

          let warehouse_index = this.headers.findIndex(
            (h) => h.value == "warehouse_id"
          );
          if (this.headers[warehouse_index].filter_values.length == 0)
            this.headers[warehouse_index].filter_values =
              res.data.filters.warehouses;

          let status_index = this.headers.findIndex((h) => h.value == "status");
          if (this.headers[status_index].filter_values.length == 0)
            this.headers[status_index].filter_values =
              res.data.filters.statuses;

          let payment_index = this.headers.findIndex(
            (h) => h.value == "payment"
          );
          if (this.headers[payment_index].filter_values.length == 0)
            this.headers[payment_index].filter_values =
              res.data.filters.payment_means;

          let country_index = this.headers.findIndex(
            (h) => h.value == "country_iso"
          );
          if (this.headers[country_index].filter_values.length == 0) {
            this.countriesLoaded = true;
            this.headers[country_index].filter_values =
              res.data.filters.countries;
          }
          let salesman_index = this.headers.findIndex(
            (h) => h.value == "salesman"
          );
          if (this.headers[salesman_index].filter_values.length == 0)
            this.headers[salesman_index].filter_values =
              res.data.filters.salesmen;
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.orders_loading = false));
    }, 200),
  },
};
</script>
